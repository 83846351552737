// 邓亚鑫-V2.2.1
<template>
  <a-checkbox
    :value="item.id || item.value"
    @change="onChange"
    class="approval-checkbox"
    :style="{ 'margin-top': index == 0 ? '' : '16px' }"
  >
    <img class="approval-img" :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')" />
    <span class="approval-name" :title="item.label || item.name">
      {{ item.label || item.name }}
    </span>
  </a-checkbox>
</template>

<script>
export default {
  name: 'approval-staff-item',
  props: ['item', 'onChange', 'index']
}
</script>

<style lang="scss" scoped>
:deep(.ant-checkbox-group) {
  display: flex;
  flex-direction: column;
}
:deep(.ant-checkbox + span){
  padding-left: 0;
}
.approval-checkbox {
  position: relative;
  .approval-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
    transform: translateY(0px);
    vertical-align: top;
  }
  .approval-name {
    display: inline-block;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  :deep(.ant-checkbox) {
    position: absolute;
    top: 10px;
    right: 16px;
  }
}
</style>
