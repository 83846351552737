<template>
  <div ref="parent">
    <a-modal
      centered
      :width="454"
      :title="modalTitle"
      :visible="visible"
      @cancel="handleCancel"
      :getContainer="() => $refs.parent"
      :maskClosable="false"
    >
      <a-form ref="formRef" :rules="rules" :model="modalVal">
        <div>
          <a-form-item label="流程名称" name="processName" :labelCol="{ span: 5 }">
            <a-input
              :maxlength="10"
              v-model:value="modalVal.processName"
              placeholder="请输入流程名称"
              style="width:320px"
            />
          </a-form-item>
          <!-- <a-form-item label="业务类型" name="business" :labelCol="{ span: 5 }">
            <a-radio-group v-model:value="modalVal.business">
              <a-radio :value="1">
                用印审批
              </a-radio>
              <a-radio :value="2">
                归档审批
              </a-radio>
            </a-radio-group>
          </a-form-item> -->
          <a-form-item label="适用范围" name="department" :labelCol="{ span: 5 }">
            <a-select
              :value="modalVal.department"
              placeholder="请选择部门或人员（默认全体人员可见）"
              :open="isSelectopen"
              @focus="selectScopeFocus"
              @blur="selectScopeBlur"
              @click="selectScopeClick"
              :getPopupContainer="e => e.parentNode"
              style="width:320px"
              disabled
            >
              <template #dropdownRender>
                <scope-tab
                  :searchLbwList="check.totalSearchList"
                  :organizationalList="organizationalList"
                  v-model:departmentVal="modalVal.departmentVal"
                  v-model:department="modalVal.department"
                  @click="clickSelectTab"
                  :visible="scopeVisible"
                  :types="types"
                  @searchDepartment="searchDepartment"
                />
              </template>
            </a-select>
          </a-form-item>
          <a-form-item label="流程类型" name="process" :labelCol="{ span: 5 }" style="margin-bottom:0">
            <a-radio-group v-model:value="modalVal.process" @change="handleProcessTypeChange">
              <a-radio :value="1">
                固定流程
              </a-radio>
              <a-radio :value="2">
                自由流程
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item v-if="modalVal.process === 1" label="审批流程" name="staffList" :labelCol="{ span: 5 }">
            <StaffProcess
              @open-approval-modal="openApprovalModal"
              @delete-approval="deleteApprovalFn"
              :approvalList="modalVal.staffList"
              :isAdd="true"
              style="width:320px"
            />
          </a-form-item>
          <section v-else class="free-tips">
            <!-- <p class="title">
              <svg>
                <use xlink:href="#iconWaring"></use></svg
              >温馨提示
            </p> -->
            <p class="text">
              自由流程允许人员在提交用印申请时自行选择审批人，请知晓此流程可能带来的风险，并规定好适用范围。
            </p>
          </section>
        </div>
      </a-form>
      <template #footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button class="comfirm" @click="comfirmAdd" :loading="loading">确定</a-button>
      </template>
    </a-modal>
    <a-modal
      :width="655"
      centered
      title="选择审批人"
      @cancel="closeApprovaModel"
      @ok="submit"
      :visible="approvalVisible"
      :getContainer="() => $refs.parent"
    >
    <a-radio-group v-model:value="approvalType" :default-value="1"  @change="radioChange" style="margin-bottom:20px">
      <a-radio :value="1">指定人员</a-radio>
      <a-radio :value="2" v-if="!isHavLeader">部门主管</a-radio>
    </a-radio-group>
      <a-row class="approval-modal"  v-if="approvalType==1">
        <a-col :span="12" style="padding: 16px 0 16px 14px; border-right: 1px solid rgba(0, 0, 0, 0.06)">
          <section
            style="margin-bottom: 24px"
            v-if="check.totalSearchList.length - modalVal.staffList.length"
          >
            <a-input
              class="search-input"
              style="width: 190px; margin-right: 17px"
              :maxlength="10"
              v-model:value="check.searchLbwVal"
              placeholder="请输入姓名查询"
            >
              <template #prefix> <img src="@/assets/svg/search.svg" /> </template>
            </a-input>
            <a-button type="primary" @click="searchApprovalList" style="border-radius: 2px">查找</a-button>
          </section>
          <div style="overflow-y: scroll" v-show="check.searchLbwList.length">
            <!-- <a-checkbox :indeterminate="check.indeterminate" :checked="check.checkAll" @change="onCheckAllChange">
              全选
            </a-checkbox> -->
            <a-checkbox-group :value="check.checkedList">
              <Virtual :list="check.searchLbwList" :size="62" :remain="8" :isScrollTop="isVirtualListScroll" :noHeight="true">
                <template #default="{ item, index }">
                  <StaffItem :item="item" :index="index" :onChange="onChange" />
                </template>
              </Virtual>
            </a-checkbox-group>
          </div>
          <div class="no-data" v-show="!check.searchLbwList.length">
            <a-empty :image="simpleImage" class="no-icon" />
          </div>
        </a-col>
        <a-col :span="12" style="padding: 16px 0 16px 14px">
          <p style="color: rgba(0, 0, 0, 0.85); margin-bottom: 24px; font-size: 14px">以下人员拥有该审批的管理权限</p>
          <div class='scroll'>
            <StaffList :list="check.checkedListRight" :onChangeList="onChangeList" />
          </div>
        </a-col>
      </a-row>
      <div v-else style="display:flex">
        <div style="margin-right:10px">找不到部门主管时，由上级部门主管代审批</div>
        <a-switch checked-children="启" un-checked-children="禁" v-model:checked="switchValue" @change="switchChange"/>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from 'vue'
import ScopeTab from './scopeTab'
import {
  getProcessStaffList,
  validateProcessName,
  getDepartmentOrganizational,
  getDepartmentShow
} from '@/apis/businessManage'
import VirtualList from '@/components/VirtualList'
import Virtual from '@/components/VirtualList/virtualList'
import ApprovalStaffItem from '@/components/VirtualList/approvalStaffItem'
import { useCheckStateAndEvent } from '@/utils/hooks'
import ApprovalStaffList from '@/views/components/approvalStaffList'
import StaffItem from './staffItem'
import StaffList from './staffList'
import StaffProcess from './staffProcess'
import { Empty } from 'ant-design-vue';

export default defineComponent({
  components: {
    ScopeTab,
    VirtualList,
    ApprovalStaffItem,
    ApprovalStaffList,
    StaffItem,
    StaffList,
    StaffProcess,
    Virtual,
    Empty
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    types: {
      type: String,
      default: 'add'
    },
    current: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const modalTitle = computed(() => (props.types === 'add' ? '添加流程' : '修改流程'))
    const formRef = ref()

    const modalVal = reactive({
      processName: undefined,
      business: 1, // 业务类型
      process: 1, // 流程类型
      department: undefined, // 适用范围
      departmentVal: {
        // 适用范围的选择值
        staff: [],
        department: []
      },
      staffList: [],
      flowSupervisor:1
    })
    const approvalFormRef = ref()
    const approvalModel = reactive({
      approvalName: undefined
    })
    const approvalRules = {
      approvalName: [
        {
          type: 'number',
          required: true,
          message: '请选择审批人'
        }
      ]
    }
    const state = reactive({
      expandedKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      approvalVisible: false,
      isSelectopen: false, // 是否打开流程范围的下拉框
      isFocus: true, // 是否流程范围获取焦点
      isClickTab: true, // 是否点的时流程范围的下拉框上
      scopeVisible: props.visible, // 流程范围下拉框的数据刷新的作用
      organizationalList: [],
      isAllFlowScope: false,
      isVirtualListScroll: 0,
      isVirtualListScroll2: 0,
      approvalType:1,//审批人类型
      switchValue:false,
      isHavLeader:false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    })
    const rules = ref()
    rules.value = {
      processName: [
        {
          required: true,
          message: '请输入流程名称'
        }
      ],
      business: [
        {
          type: 'number',
          required: true,
          message: '请选择业务类型'
        }
      ],
      process: [
        {
          type: 'number',
          required: true,
          message: '请选择流程类型'
        }
      ],
      staffList: [
        {
          type: 'array',
          required: true,
          message: '请选择审批流程'
        }
      ]
    }
    const validateProcessNameFn = async () => {
      // 校验流程名字是否重复
      if (props.types === 'edit') {
        return { success: true }
      }
      const res = await validateProcessName({ flowName: modalVal.processName })
      return res
    }

    const enhancerMapFn = {
      // 强化hooks函数
      closeApprovaModel(){
        state.approvalVisible = false
      },
      selectApprovalFn() {
        state.approvalVisible = false
        setTimeout(() => {
          formRef.value.clearValidate('staffList')
          state.isVirtualListScroll2 += '1'
        })
      },
      deleteApprovalFn() {
        state.isVirtualListScroll2 = {}
      },
      searchApprovalList() {
        typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
      },
      openApprovalModal() {
        state.approvalVisible = true
        state.approvalType=1
        state.switchValue=false;
        // console.log(modalVal.staffList)
       if(modalVal.staffList.length>0){
         let tmp = modalVal.staffList.filter(val=>val.type==2)
        //  console.log(tmp)
         if(tmp.length==0){
           state.isHavLeader=false;
         }else{
           state.isHavLeader=true;
         }
        }else{
          state.isHavLeader=false
        }
        //  console.log(state.isHavLeader)
        setTimeout(() => {
          typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
        })
      },
      changeStaffList() {
        modalVal.staffList = check.staffList.map(item => item)
      }
    }
    const {
      check,
      onChange,
      onCheckAllChange,
      reloadSearchLbwList,
      closeApprovaModel,
      selectApprovalFn,
      searchApprovalList,
      deleteApprovalFn,
      openApprovalModal,
      initialApproalList,
      onChangeList
    } = useCheckStateAndEvent(enhancerMapFn)

    const comfirmAdd = () => {
      // 提交函数
      emit('update:loading', true)
      formRef.value
        .validate()
        .then(async () => {
          const params = {}
          const res = await validateProcessNameFn()
          if (!res.success) {
            emit('update:loading', false)
            // emit('update:visible', false)
            return
          }
          params.flowName = modalVal.processName
          params.flowType = modalVal.process
          params.businessType = modalVal.business
          if (modalVal.process === 1) {
            console.log(modalVal.staffList,"参数");
            params.flowUserRelsDTO = modalVal.staffList.map(item => {
              if(item.staffId==-1||item.staffId==null){
                item.staffId=null
              }
              let tmp={
                staffId: item.staffId==null?null:(item.id || item.staffId),
                type:item.staffId==null?2:1,
                upDpLook:item.upDpLook?item.upDpLook:null
              }
              return tmp
            })
          }
          let tmp =[];
          if (modalVal.process === 1)tmp=params.flowUserRelsDTO.filter(val=>val.staffId == null);
          console.log(tmp);
          if(tmp.length>0){
            params.flowSupervisor=tmp[0].upDpLook?3:2
          }else{
            params.flowSupervisor=1
          }
          if (props.types === 'edit') {
            // 修改流程 判断流程范围
            console.log("12323213",state.isAllFlowScope,modalVal.departmentVal.department.includes('sss'),modalVal.departmentVal.staff.length);
            // if (
            //   state.isAllFlowScope &&
            //   modalVal.departmentVal.department.includes('sss') &&
            //   !modalVal.departmentVal.staff.length
            // ) {
            //   params.flowScope = 1
            // } else {
            //   iterationProcessRange(params, modalVal)
            // }
            if ((check.totalSearchList.length==modalVal.departmentVal.staff.length)||(!modalVal.departmentVal.staff.length && !modalVal.departmentVal.department.length)) {
              params.flowScope = 1
              params.flowScopeVO = []
            } else {
              iterationProcessRange(params, modalVal)
            }
          } else {
            if (!modalVal.departmentVal.staff.length && !modalVal.departmentVal.department.length) {
              params.flowScope = 1
            } else {
              iterationProcessRange(params, modalVal)
            }
          }
          // return
          emit('modalSubmit', params)
        })
        .catch(() => emit('update:loading', false))

      function iterationProcessRange(params, modalVal) {
        // 处理 选择流程范围把值提取出来的函数

        params.flowScope = 2
        params.flowScopeDTO = []
        modalVal.departmentVal.department.length &&
          (modalVal.departmentVal.department = modalVal.departmentVal.department.filter(
            item => typeof item === 'number'
          ))
        modalVal.departmentVal.staff.length > modalVal.departmentVal.department.length
          ? modalVal.departmentVal.staff.forEach((item, index) => {
              // console.log(item, 'item')
              params.flowScopeDTO[index] = {
                staffId: item,
                departmentId: modalVal.departmentVal.department[index]
              }
            })
          : modalVal.departmentVal.department.forEach(
              (item, index) =>
                (params.flowScopeDTO[index] = { departmentId: item, staffId: modalVal.departmentVal.staff[index] })
            )
        !params.flowScopeDTO.length ? (params.flowScope = 1) : null
      }
    }
    const getProcessStaff = async () => {
      // 获取流程的审批人
      const res = await getProcessStaffList()
      check.searchLbwList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
      check.totalSearchList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    }
    const getOrganizationalList = async params => {
      // 获取流程范围中部门列表的函数
      const res = await getDepartmentShow(params)
      const permissionList = res.data
      state.organizationalList = permissionList.length ? permissionList : []
    }
    const onExpand = expandedKeys => {
      // 部门列表的父级展开
      state.expandedKeys = expandedKeys
      state.autoExpandParent = false
    }
    const onCheck = checkedKeys => {
      // 部门列表的选择回调
      modalVal.power = checkedKeys
    }
    const selectScopeFocus = () => {
      // 处理流程范围获取焦点函数
      state.isSelectopen = true
      state.isClickTab = true
      setTimeout(() => {
        state.isFocus = false
        state.isClickTab = false
      }, 100)
    }
    const selectScopeBlur = () => {
      // 处理流程范围失去焦点函数
      setTimeout(() => {
        state.isFocus = true
      }, 20)
    }
    const selectScopeClick = () => {
      // 处理流程范围下拉框的点击
      !state.isFocus && (state.isSelectopen = !state.isSelectopen)
      state.isClickTab = true
      setTimeout(() => {
        state.isClickTab = false
      }, 80)
    }
    const clickSelectTab = () => {
      // 处理流程范围下拉框的点击
      state.isClickTab = true
      state.isFocus = true
      setTimeout(() => {
        state.isClickTab = false
      }, 100)
    }
    const clickNoTab = () => {
      // 处理点击非流程范围下拉框区域
      if (!state.isClickTab) {
        state.isSelectopen = false
        state.isClickTab = true
      }
    }

    const handleCancel = () => {
      // 取消流程弹窗
      formRef.value.resetFields()
      emit('update:visible', false)
    }
    const filterSelectProcessRange = (processType, processRangeVal) => {
      // 处理下修改时流程，赋值流程范围
      modalVal.departmentVal = {
        staff: [],
        department: []
      }
      if (processType === 2) {
        processRangeVal.forEach(item => {
          item.staffId && modalVal.departmentVal.staff.push(item.staffId)
          item.departmentId && modalVal.departmentVal.department.push(item.departmentId)
        })
      } else {
        check.totalSearchList.map(item=>{
          modalVal.departmentVal.staff.push(item.staffId)
        })
        // modalVal.departmentVal.department.push('sss')
        state.isAllFlowScope = true
      }
    }

    const handleProcessTypeChange = () => {
      if (modalVal.process === 1) {
        modalVal.staffList = []
        initialApproalList()
      }
    }

    const searchDepartment = value => {
      console.log(value)
      getOrganizationalList({ searchDept: value })
    }

    const radioChange=(e)=>{
      state.approvalType=e.target.value;
      state.switchValue=false;
    }
    const switchChange=(checked)=>{
      state.switchValue=checked;
    }

    const submit=()=>{
      if(state.approvalType==1){
        selectApprovalFn()
      }else{
        state.approvalVisible = false;
        check.staffList.push({type:2,staffId:null,id:null,avatarId:null,staffName:"部门主管",name:"部门主管",upDpLook:state.switchValue})
        enhancerMapFn.changeStaffList()
      }
    }
    onMounted(() => {
      document.body.addEventListener('click', clickNoTab, false)
      getProcessStaff()
      getOrganizationalList()
    })

    onBeforeUnmount(() => {
      document.body.removeEventListener('click', clickNoTab)
    })

    watch(
      () => state.approvalType,
      newValue => {
        if (newValue == 2) {
          check.checkedList = []
          check.checkedListRight = []
          reloadSearchLbwList()
        }
      }
    )

    watch(
      () => props.visible,
      newVisible => {
        if (!newVisible) {
          state.scopeVisible = newVisible
          return
        }
        let newCheckStaffList = []
        state.isAllFlowScope = false
        if (props.types === 'add') {
          modalVal.processName = undefined
          modalVal.business = 1
          modalVal.process = 1
          modalVal.department = undefined
          modalVal.departmentVal = {
            staff: [],
            department: []
          }
          modalVal.staffList = []
          state.expandedKeys = []
          state.autoExpandParent = false
          state.searchLbwList = state.totalSearchList
        } else if (props.types === 'edit') {
          modalVal.processName = props.current.flowName
          modalVal.business = props.current.businessType
          modalVal.process = props.current.flowType
          modalVal.department = '已选择'
          modalVal.flowSupervisor=props.current.flowSupervisor
          filterSelectProcessRange(props.current.flowScope, props.current.flowScopeVO)
          modalVal.staffList =
            props.current.flowUserRelsVO && props.current.flowUserRelsVO.length
              ? props.current.flowUserRelsVO.map(item => {
                if(item.type==2){
                  item.staffId=-1;
                  item.staffName="部门主管";
                  if(props.current.flowSupervisor==3){
                  item.upDpLook=true
                  }else{
                    item.upDpLook=false
                  }
                }
                item.id = item.staffId
                item.name=item.staffName
                return item
              })
              : []
          newCheckStaffList = modalVal.staffList.map(item => item)
          state.expandedKeys = []
          state.autoExpandParent = false
        }
        initialApproalList(newCheckStaffList)
        state.scopeVisible = newVisible
        typeof state.isVirtualListScroll2 === 'number' ? state.isVirtualListScroll2++ : (state.isVirtualListScroll2 = 0)
      }
    )

    return {
      comfirmAdd,
      formRef,
      modalVal,
      rules,
      modalTitle,
      onExpand,
      ...toRefs(state),
      onCheck,
      approvalFormRef,
      approvalModel,
      approvalRules,
      selectScopeFocus,
      selectScopeClick,
      selectScopeBlur,
      clickSelectTab,
      selectApprovalFn,
      closeApprovaModel,
      onCheckAllChange,
      onChange,
      check,
      deleteApprovalFn,
      handleCancel,
      searchApprovalList,
      openApprovalModal,
      handleProcessTypeChange,
      searchDepartment,
      onChangeList,
      searchDepartment,
      radioChange,
      switchChange,
      submit
    }
  }
})
</script>

<style lang="scss" scoped>
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;
  &:hover {
    border-color: transparent;
  }
}
.tips {
  height: 17px;
  opacity: 1;
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: #999999;
  line-height: 17px;
  margin-left: 226px;
  transform: translateY(-15px);
}
.search-input {
  width: 470px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 1px;
}
:deep(.ant-checkbox-wrapper) {
  display: block;
  margin-left: 0;
}
.btn {
  width: 100px;
  height: 38px;
  opacity: 1;
  border-radius: 5px;
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 38px;
}
.search-btn {
  margin-top: 0;
}
.free-tips {
  margin-left: 80px;
  .text {
    width: 320px;
    height: 34px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
  }
}

:deep(.ant-select) {
  .ant-select-selection-item-remove {
    display: none;
  }
}
:deep(.ant-form-item) {
  margin-bottom: 10px;
}
:deep(.ant-modal-header) {
  border-bottom: 0;
}
.approval-modal {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.scroll {
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #dddddd;
    border-radius: 4px;
  }
  overflow-y: scroll;
  height: 376px;
}
.no-data {
  height: 355px;
  .no-icon {
    height: 355px;
    transform: translateY(36%);
  }
}
</style>
